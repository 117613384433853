.profile {
  display: block;
}
.header {
  color: #eb7346;
  margin: 20px 0 40px 0;
}
.challengeItem h4 {
  font-size: 36px;
  font-weight: 400;
}
.profileInfoItem {
  padding-bottom: 0 !important;
  margin-bottom: 10px;
  word-break: break-all
}

.boldText {
  font-weight: bold;
}

.rowContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

}
.shortProfileInfo {
  margin: 0;
  max-width: 700px;
  margin-right:20px;
}
.shortProfileInfoHeader{
  min-height: 400px;
  padding:0px;
}
.editButton{
  height: 45px;
  font-size: 30px;
  position: relative;

}
.socialIcons {
  margin: 50px 0;
}
.socialIcon {
  margin: 0 10px 0 0;
}

.challengeItem div:first-child div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("/images/avatars/not_found.png");
  background-position: center center;
  margin-left: 0;
}

.spinner {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 700px){
  .shortProfileInfo{
    margin: 0;
  }
  .profileInfoItem {
    font-size: 17px;
  }
}
