.inputContainer {
  position: relative;
}

.label {
  display: inline-block;
  font-size: 20px;
  margin-left: 30px;
}

/* Customize the label (the container) */
.inputWrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 24px;
  position: relative;
  margin-right: 25px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.inputWrap input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  border-radius: 4px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.inputWrap:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.inputWrap input:checked ~ .checkmark {
  background-color: #EB7346;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.inputWrap input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.inputWrap .checkmark:after {
  left: 8px;
  top: 2px;
  width: 7px;
  height: 15px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
