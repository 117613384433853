.tagText,
.tagClose {
  display: block;
}

.tag {
  flex: 0 0 auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  min-width: 103px;
  padding: 4px 6px 4px 10px;
  border: 0px solid transparent;
  border-radius: 13px;
  letter-spacing: 0.3px;
}

.tagText {
  flex: 1;
  font-family: 'Open Sans', 'OpenSans', OpenSans, opensans, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.4;
  color: #ffffff;
}

.tagClose {
  flex: 0 0;
  margin-left: 10px;
  cursor: pointer;
}

.buttonStyle {
  text-align: center;
  cursor: pointer;
}
