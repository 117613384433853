.form {
  position: relative;
  width: 100%;
  padding: 0.4rem 2.67rem 2rem;
  background: #fff;

  .title {
    color: #EB7346;
    font-weight: 400;
    margin-bottom: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column-reverse;
    width: 100%;
  }

  .description {
    font-size: 14px;
    line-height: normal;
    padding-bottom: 1rem;
  }
}

.form span.emailError {
  margin-bottom: .5rem;
  color: red;
}

.submit {
  width: 100%;
  height: 45px;
  min-width: auto;
  margin-top: .8rem;
  transition: background-color .1s;
}

.submitLabel {
  font-size: 20px!important;
}

.backButton{
  position: relative;
  padding: 0;
  background: none;
  border-width: 0;
}
span.titleText{
  color:#eb7346;
}

.arrowImage{
  transform:rotate(180deg);
  color:orange
}
