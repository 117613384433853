.productsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(auto, 3);
}

.mapChart {
  padding: 0 0 32px 32px;
  grid-row: 1 / 4;
  grid-column: 2 / 2;
}

.productsListContainer {
  padding-bottom: 32px;
  padding-right: 32px;
}

.productsList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(7, 1fr);
  grid-gap: 10px;
  padding: 16px 0;
  border-top: 1px solid #979797;
  border-bottom: 1px solid #979797;
}

.productsListCountry {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  padding: 0;
}

.productHeading {
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  padding: 0;
}

.productsListHeading {
  display: flex;
  padding-bottom: 16px;
}

.markerIcon {
  margin-right: 10px;
}

@media (max-width: 996px) {
  .productsContainer {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(auto, 4);
  }

  .mapChart {
    grid-row: 2 / 2;
    grid-column: 1 / 1;
  }

  .mapChart {
    padding: 32px 0;
  }
  .productsListContainer {
    padding-right: 0;
  }
}

@media (max-width: 640px) {
  .productsList {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(10, 1fr);
  }
}
