.selectContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  justify-content: space-between;
}

.selectElement {
  display: flex;
  flex-direction: row;
}

.okButton {
  min-width: 64px;
  height: 33px;
  margin-top: 35px;
  align-self: center;
  margin-left: 10px;
}

.downloadButton {
  align-self: center;
  height: 33px;
  margin-top: 35px;
  margin-left: 20px;
}

.iconButtonStyle {
  border: none;
  background: none;
  padding-right: 20px;
}

.textCenter {
  display: flex;
  justify-content: center;
}

.customSelect{
  min-width: 280px;
}

@media only screen and (max-width: 1024px) {
  .selectContainer {
    flex-direction: column;
  }
  .selectElement {
    margin-top: 20px;
    margin-bottom: 10px;
  }
}