.title{
  font-size: 18px;
  font-weight: 600;
  color:white;
  font-family: 'Open Sans', 'OpenSans', OpenSans, opensans, sans-serif;
  padding: 16px 24px;
  border-bottom: 1px solid rgba(255,255,255, 0.3);
  text-decoration: none;
}

.list{
  flex-grow: 1;
}

 li .navLink, .navLinkTrigger, .navLinkTriggerOpened {
  position: relative;
  cursor: pointer;
  display: block;
  font-size: 16px;
  font-family: "Quicksand", Quicksand, quicksand, Gotham, sans-serif;
  color: white;
  letter-spacing: 1px;
  padding: 16px 24px;
  font-weight: 600;
  text-decoration: none;
  border-bottom: 1px solid rgba(255,255,255, 0.3);
}

 .logoffButton.navLink{
   display: block;
   width:100%;
   text-align: left;
   background-color: transparent;
   border: none;
   padding: 16px 40px;
 }

.logoffButton:hover{
  background-color: rgba(255,255,255,0.1);
}

 .collapsibleContentOuter {
  background-color: rgba(255,255,255,0.1);
  & .navLink.active{
    background-color: initial;
    color:#EB7346;
  }
}

.listItemCollapsible {
  & .navLinkTrigger {
    display: flex;
    justify-content: space-between;
  }
  & .navLinkTriggerOpened {
    background-color: rgba(255, 255, 255, 0.1);
  }
  & .navLinkTriggerText {
    color: white;
    text-decoration: none;
  }
  & .navLinkTrigger:after, & .navLinkTriggerOpened:after {
    font-family: 'FontAwesome';
    content: '\f107';
    position: relative;
    display: block;
    transition: transform 300ms;
  }
  & .navLinkTriggerOpened:after {
    transform: rotateZ(180deg);
  }
}

li .subLink.active{
  padding-left: 40px;
  background: none;
  color: #EB7346;

}

.sideMenuFooter{
  height: 81px;
  box-shadow: inset 0 0 5px #000;
  display: flex;
  justify-content: center;
}
