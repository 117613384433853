.navLink {
    position: relative;
    cursor: pointer;
    display: block;
    font-size: 16px;
    font-family: "Quicksand", Quicksand, quicksand, Gotham, sans-serif;
    color: white;
    letter-spacing: 1px;
    padding: 16px 24px;
    font-weight: 600;
    text-decoration: none;
    border-bottom: 1px solid rgba(255,255,255, 0.3);
  }
.navLink:hover {
    background-color: rgba(255,255,255,0.1);
  }

.navLink.active{
  background-color: #EB7346;
}

.subLink{
  padding-left: 40px;
}

.subLink.active{
  background: none;
  color:#EB7346;
}
