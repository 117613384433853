.buttonsBlock {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  & button{
    margin:0 10px 15px;
  }
}
.logoffText{
  text-align: center;
}