.myProfile {
  display: flex;
  flex: 1 1 auto;
  height: 100%;
  width: 100%;
}
.myProfileHeader {
  color: #eb7346;
}

.logOffButton {
  background: none;
  border:none;
}

.profileNavLink {
  color: #eb7346;
  display: block;
  font-weight: bold;
  margin: 40px 0;
  text-decoration: none;
  height: 30px;
  padding: 6px 0 6px 20px;
  border-left: 5px solid #fff;
  cursor: pointer;
}
.active {
  border-left: 5px solid #eb7346;
}
.myProfileBlocks {
  padding: 10px 0 0 20px;
  flex: 1;
}
.myProfileBlocks h1 {
  color: #eb7346;
}

.title {
  margin-bottom: 30px;
}

.contentWrap{
  display: flex;
  flex-flow: row nowrap;
}

@media (max-width: 1000px) {
  .myProfileBlocks {
    padding-left: 0px;
  }
}
