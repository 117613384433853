.parted {
  display: flex;
  flex-flow: row nowrap;
  padding: 0;
  background-color: white;
}
.parted.reverse {
  flex-direction: row-reverse;
}

.descriptionCol {
  display: flex;
  justify-content: flex-end;
  flex: 0 0 50%;
}
.descriptionCol.reverse {
  justify-content: flex-start;
  // padding-left: 100px;
}

.decsriptionInnerWrap {
  // max-width: 518px;
  // max-width: 465px;
  padding: 0;
  line-height: 1.4;
}

.descriptionCol.reversed {
  padding-left: 30px;
}

.imgCol {
  flex: 0 0 50%;
  background-color: gray;
}

.img {
  background-size: cover;
  width: 100%;
  height: 100%;
}


@media (max-width: 996px) {
  .decsriptionInnerWrap {
    flex: 1;
  }

  .descriptionCol {
    flex: 0 0 100%;
  }
  .imgCol {
    display: none;
  }
}
