.memberCards {
  display: flex;
  flex-flow: row wrap;
  padding: 45px 0;
  min-height: 300px;
}
.memberCards > .memberCardWrap {
  width: 25%;
  padding: 10px;
  margin-bottom: 30px;
  transition: background-color 0.2s;
}

.memberCards > .memberCardWrap:hover {
  background-color: rgba(235, 115, 70, 0.13);
  border-radius: 5px;
}

@media (max-width: 996px) {
  .memberCards > .memberCardWrap {
    width: 33.3%;
    padding: 10px;
  }
}
@media (max-width: 720px) {
  .memberCards > .memberCardWrap {
    width: 50%;
    padding: 10px;
  }

  .memberCards {
    padding:  0;
  }
}

@media (max-width: 400px) {
  .memberCards > .memberCardWrap {
    width: 100%;
    padding: 10px;
  }
}
