.alertsContainer {
  position: fixed;
  max-width: 600px;
  left: 0;
  right: 0;
  margin:auto;
  margin-top: 50px;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 0 20px;
  z-index: 1000;
}

.error {
  padding: 12px 24px;
  margin-bottom: 10px;
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  &:last-of-type{
    margin-bottom: 0px;
  }
}

.error.danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.error.success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.error.warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.errorText {
  font-family: BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 16px;
}
