.coalitionFinder {
}

.inputs {
  display: flex;
  flex-flow: row wrap;
  padding: 10px 0;
}

.inputs > * {
  margin-right: 10px;
  margin-bottom: 10px;
}

.inputs > *:last-of-type {
  margin-right: 0px;
}

.searchInputContainer {
  flex: 1 1 auto;
}

.selectsBlock{
  display: flex;
}

.selectContainer {
  width:50%;
  max-width: 250px;
  &:first-of-type{
    margin-right: 20px;
  }
}

.tags {
  display: flex;
  flex-flow: row wrap;
  padding: 10px 0;
  // border: 1px solid black;
}

.tags > * {
  margin-right: 10px;
  margin-bottom: 10px;
}

.tags > *:last-of-type {
  margin-right: 0px;
}

.footer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.showMoreButton {
  border-radius: 5px;
  font-size: 20px;
}

@media (max-width:996px) {
  .selectContainer {
    flex: 1 1 auto;
    max-width: none;
    &:first-of-type{
      margin-right: 20px;
    }
  }
  .selectsBlock{
    width:100%;
  }

  .searchInputContainer {
    flex: 1 0 auto;
    width:100%;
  }
}

@media (max-width:640px) {
  .selectContainer {
    flex: 1 1 100%;
    width:100%;
    margin-bottom: 10px;
    &:first-of-type{
      margin-right: 0px;

    }
    &:last-of-type{
      margin-right: 0px;

    }
  }
  .selectsBlock{
    flex-flow:row wrap;
  }
}

.spinner {
  display: flex;
  justify-content: center;
}

