.accessingCapCol {
  display: flex;
  flex-direction: column;
  flex: 0 0 50%;
  padding: 0 40px;
  max-width: 720px;
}
.accessingCapDescriptionText {
  padding-bottom: 0;
}

.videoCol {
  flex: 0 0 50%;
}

.iframeWrapper {
  width: 100%;
  height: 100%;
}

.accessingCapHeading {
  width: 50%;
}

.partnersList {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.partnerItem {
  display: inline-block;
  margin: 32px;
}

.partnerLogo {
  max-height: 96px;
  width: 192px;
  max-width: 100%;
}

.accessingCapContainer {
  display: flex;
  flex-flow: row nowrap;
}

.faqContainer,
.accessingCapContainer {
  margin-bottom: 96px;
}

.partnersContainer {
  margin-bottom: 128px;
}

.availableProductsContainer {
  margin-bottom: 72px;
}

@media (max-width: 996px) {
  .partnersList,
  .accessingCapContainer {
    flex-direction: column;
  }
  .faqContainer,
  .accessingCapContainer,
  .partnersContainer {
    margin-bottom: 72px;
  }
  .availableProductsContainer,
  .accessingCapCol {
    padding-bottom: 48px;
  }
  .videoCol {
    flex: 0 0 100%;
    padding: 0 40px;
  }
  .iframeWrapper {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
  }
  .iframe {
    position: absolute;
    top: 0;
    left: 0;
  }
  .accessingCapCol {
    flex: 0 0 100%;
    order: -1;
    max-width: 100%;
  }
}

@media (max-width: 640px) {
  .accessingCapCol,
  .videoCol {
    padding-right: 20px;
    padding-left: 20px;
  }
}
