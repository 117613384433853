.dropDownContainerStyles {
  display: flex;
  width: 244px;
  position: absolute;
  top: 100%;
  right: -45%;
  z-index: 5;
  box-shadow: 0px 2px 4px rgba(0,0,0,0.5);
}

.container {
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;

}

.font {
  width: 80%;
  padding-top: 10%;
  font-family: 'Open Sans', 'OpenSans', OpenSans, opensans, sans-serif;
  font-size: 16px;
  text-align: center;
  & .coalitionRequests {
    color: #EB7346;
    font-weight: bold;
  }
}
