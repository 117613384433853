.ol,.ul {
  display: inline-block;
  list-style-image: none;
  font-size: 20px;
  text-align: left;
  font-family: 'Open Sans', 'OpenSans', OpenSans, opensans, sans-serif;
  font-weight: 400;
  line-height: 1.6;
  color: #262d2d;
  margin-bottom: 32px;
}
.ol {
  padding: 0 0 0 14px;
}
.ol.none {
  list-style-type: none;
}
.ul.none {
  list-style-type: none;
}
.ol.decimal {
  list-style-type: decimal;
}

.ul.circle{
  list-style-type: circle;
}

.ul.disc{
  list-style-type: disc;
}

.ul.hash{
  list-style-type: none;
  padding: 0 0 0 14px;
}

.dashedElement:before {
  content:'-';
  position: absolute;
  left: -10px;
  color: #EB7346;
}
.hashedElement:before {
  content:'#';
  position: absolute;
  left: -22px;
  color: #EB7346;
}

.listItem {
  position: relative;
  margin-left: 10px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.linkItem {
  color: #eb7346;
}

.description {
  color: grey;
  font-size: 15px;
}

