.columns {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 0;
}
.columns > * {
}
.columns.one > * {
  width: 100%;
}
.columns.two > * {
  width: 50%;
}
.columns.three > * {
  width: 32%;
  padding: 0;
}
.columns.four > * {
  width: 25%;
}
.columns.five > * {
  width: 20%;
}

@media (max-width: 996px) {
  .columns > * {
    margin-bottom: 30px;
  }
  .columns.two > * {
    width: 100%;
  }
  .columns.three > * {
    width: 100%;
  }
}
