.title {
  margin-bottom: 30px;
  color: #eb7346;
}
.content{
  display: flex;
  flex-flow: row wrap;
  padding: 100px 0;
  align-items: center;
}

.imageContainer,.descriptionContainer {
   flex: 0 0 50%;
}
.descriptionContainer {
  padding-right: 20px;
}

.imageContainer img {
  width:100%;
}

@media (max-width: 720px) {
  .content{
    display: flex;
    flex-flow: column wrap;
    align-content: center;
  }

  .descriptionContainer {
    flex: 0 0 50%;
    margin-bottom: 50px;
    padding-right: 0;
  }
}