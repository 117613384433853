.dropdown {
  display: inline-block;
  position: relative;
  width:100%;
  padding: 0;
  opacity: 1;
  border-radius: 0;
}

.dropdown::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border:10px solid;
  border-color: transparent transparent white transparent;
  bottom: 100%;
  right: 10%;
}
