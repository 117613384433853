.challengeItem {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 50px;
}
.challengeItem:last-of-type {
  margin-bottom: 0;
}

.imgCol {
  flex: 0;
}
.imgBlock {
  height: 180px;
  width: 180px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #eb7346;
  margin-left: 40px;
  margin-right: 40px;
}
.img{
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.descriptionCol {
  flex: 1;
}

.title {
  color: #eb7346;
}

.description {
}

@media (max-width: 720px) {
  .imgCol {
    flex: 1 100%;
    margin-bottom: 40px;
  }
}

@media (max-width: 400px) {
  .img {
    margin-left: 0;
    margin-right: 0;
  }
}
