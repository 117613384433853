.guidelines {
  display: block;
  flex: 1 1 auto;
}

.actionsTitleColor {
  color: #fff;
}

.endorsementsImagesContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.imageWithDescription {
  margin-bottom: 5rem;
}

.navigatorSection, .endorsementSection {
  padding-top: 3rem;
}

.navigatorSectionContent {
  max-width: 520px;
}

.descriptionCustomWrapper {
  padding-right: 10px;
}

.modalButton{
  position: relative;
  display: inline-block;
  text-align: left;
  text-decoration: underline;
  color: #eb7346;
  border: none;
  padding:0;
  margin: 0;
  margin-left: 10px;
  background: none;
  font-family: 'Open Sans', 'OpenSans', OpenSans, opensans, sans-serif;
  line-height: 1.6;
}
.modalButton:before {
  content:'-';
  position: absolute;
  left: -10px;
  color: #EB7346;
}

