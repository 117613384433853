.error {
  display: inline-block;
  color: red;
  font-weight: 400;
  font-size: 14px;
  margin-top: 5px;
}
.error:before {
  content: '*';
  margin-right: 5px;
}
