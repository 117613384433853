.card {
  display: flex;
  align-items: flex-start;
  max-width: 1035px;
  margin: 0 auto;
  padding-bottom: 65px;
}

.descriptionCol {
  position: relative;
  flex: 1 1 auto;
  padding-right: 25px;
}

.imgCol {
  flex: 0 0 505px
}

.img {
  background-size: cover;
  width: 100%;
  height: 100%;
}

@media (max-width: 996px) {
  .card {
    flex-direction: column-reverse;
  }

  .descriptionCol {
    margin-bottom: 0px;
  }
  .imgCol {
    display: block;
    flex: 0 0 auto;
    width: 100%;
    margin-bottom: 30px;
  }
}
