.main {
    flex: 1 0 auto;
    display:flex;
    flex-flow: column nowrap;

}
@media (max-width: 1000px) {
    .main {
        margin-top:50px;
    }
}
