.title{
  font-size: 18px;
  font-weight: 600;
  color:white;
  font-family: 'Open Sans', 'OpenSans', OpenSans, opensans, sans-serif;
  padding: 16px 24px;
  border-bottom: 1px solid rgba(255,255,255, 0.3);
  text-decoration: none;
}

.sideMenuFooter{
  height: 81px;
  display: flex;
  justify-content: center;
  border: 10px solid #ebe9e9
}