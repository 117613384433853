.link {
  color: #262d2d;
  text-decoration: none;
  width: 100%;
  display: flex;
  font-size: 20px;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.linkListItem {
  margin-bottom: 30px;
  width: 100%;
  max-width: 217px;
}
.linkListItem:last-of-type {
  margin-bottom: 0px;
}

.button {
  padding: 3px;
  border: 1px solid;
  background-color: #eb7346;
  font-size: 16px;
  color: white;
  &:hover {
    background-color: #f19069;
  }
}

.text {
  color: #262d2d;
  width: 100%;
  display: flex;
  font-size: 15px;
  border: 2px solid #eb7346;
  padding: 5px;
  flex-flow: row nowrap;
  justify-content: space-between;
}