.collapsePanel {
  border-top: 1px solid #eb7346;

  &:last-of-type {
    border-bottom: 1px solid #eb7346;
  }
}

.question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  padding: 0;
  margin: 0;
  width: 100%;
  background: transparent;
}

.questionText {
  padding: 20px 0;
}

.question:after {
  font-family: 'FontAwesome';
  font-size: 32px;
  content: '\f107';
  position: relative;
  display: block;
  padding: 20px;
  transition: transform 300ms;
}

.questionTextOpened {
  font-weight: bold;
}

.questionOpened:after {
  transform: rotateZ(180deg);
}
