.header {
  display: flex;
  align-self: flex-start;
  width: 100%;
  min-height: 50px;
  background-color: #eb7346;
  font-family: 'Quicksand', Quicksand, quicksand, Gotham, sans-serif;
}

.headerContentWrap {
}

.desktopHeaderContent{
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.mobileHeaderContent{
  display: none;
}

.mobileLogoWrapper{
  flex-grow: 1;
  align-self: stretch;
  display: flex;
  & .logoLink{
    display: block;
    margin: 0 auto;
  }
}

.headerNavBar {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
}

.headerLinkList {
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  list-style: none;
}

.headerLinkListItem {
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
  outline: 0;
}

.headerNavLink {
  display: flex;
  align-items: center;
  height: 100%;
  font-weight: 700;
  color: transparent;
  text-decoration: none;
  border: none;
  outline: 0;
  border: 0;
}

.headerNavLink {
  span,
  button {
    color: white;
  }
}
.active:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: white;
  border: none;
}

.login {
  position: relative;
  align-self: center;
  justify-content: center;
}

@media (max-width: 1000px) {
  .header {
    position: fixed;
    z-index: 10;
    top: 0;
    transition: top 0.6s;
  }
  .headerHidden {
    top: -50px;
  }
  .desktopHeaderContent{
    display: none;
  }

  .mobileHeaderContent{
    display: flex;
    align-items: center;
    height: 100%;
  }
}
