.common {
  border: 0px solid transparent;
  flex: 1 1 auto;
  width:100%;
  border-radius: 0;
  padding: 0px 2px 1px 10px;
  height:34px;
  font-family: 'Open Sans', 'OpenSans', OpenSans, opensans, sans-serif;
  font-size: 18px;
  line-height: 1.5;
  background: white;
}

.commonDefault{
  color: gray;
}

.commonContainer {
  position: relative;
  font-size: 20px;
}

.commonWrap {
  display: flex;
  flex-flow: row nowrap;
  border: 2px solid lightgray;
  border-radius: 4px;
  overflow: hidden;
}
.fileWrap {
  display: flex;
  flex-flow: row nowrap;
}
.leftIconWrap,
.rightIconWrap {
  flex: 0 0;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  padding: 2px;
}

.label {
  display: block;
  margin-bottom: 1rem;
  font-size: 20px;
}

.placeholder {
  display: none;
}

