.button {
  // display: inline-flex;
  justify-content: center;
  height: 40px;
  min-width: 167px;
  padding: 0 7px;
  margin-right: 30px;
  text-align: center;
  line-height: 1;
  border: none;
  background-color: #eb7346;
  transition: all 0.1s;
  &.withIcon {
    justify-content: space-between;
  }
}

.button.alt .label {
  border: 2px solid #eb7346;
  background-color: white;
}
.button.alt .label {
  color: #eb7346;
}
.button:hover {
  background-color: #f19069;
}

.button[disabled] {
  background-color: #cccccc;
}

.button.secondary {
  background-color: #fff;
  border: 1px solid #eb7346;
}

.label {
  color: white;
  margin: auto;
  font-family: 'Quicksand', Quicksand, quicksand, Gotham, sans-serif;
  font-size: 14px !important;
  letter-spacing: 0.1px;
}

.label.secondary {
  color: #eb7346;
}

.button.withIcon {
  .label {
    margin: auto 1rem;
  }
}
