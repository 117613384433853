.mainTitle {
  display: flex;
  flex-direction: row;
}

.container{

}
.column {
  display: flex;
  flex-direction: row;
}

.email {
  margin-top: 10px;
  margin-bottom: 10px;
}

.container .title {
  display: inline-block;
  margin-bottom: 0.5rem;
  flex-shrink: 2;
  padding-right: 20px;
}

.inputGroup {
  margin-bottom: 2rem;
  display: flex;
}
.profileInfoForm{
  margin: 20px 0 0 0 ;
}
.inputDoubleGroup {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > *:first-child {
    margin-right: 1rem;
  }

  > *:last-child {
    margin-left: 1rem;
  }
}
.singleLabel{
  display: block;
  font-size: 20px;
  margin:1.2rem 0 1.5rem 0;
  flex: 1;
}
.email{
  font-size: 20px;
}
.uploadImageContainer .challengeItem>.img{
  margin: 0 0  0 -20px !important;
}
.uploadImageText{
  font-size: 16px;
  padding-bottom: 0;
  max-width: 100%;
  width: 250px;
  display: flex;
  flex: 1;
}
.uploadButton{
  width: 220px;
}
.inputContainer,.textAreaContainer {
  flex: 1;
  margin-right: 20px;
}
.singleInputContainer{
  flex: 0.48 1;
}
.checkboxContainer  {
  flex: 1;
}
.checkboxWrap{
  display: flex;
  flex-flow: row nowrap;
}
.checkBoxLabel{
  font-size: 16px !important;
}
.privacyText {
  font-size: 12px;
}

.submitInfo {
  margin-top: 0.5rem;
}

.buttonsContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 4rem;
  margin-bottom: 2rem;
  button{
    margin-bottom: 20px;
  }
}
.profileInfoFormButtons p{
  font-size: 20px !important;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  display: flex;
}

.imageLoader {
  margin-top: 2rem;
}

@media (max-width: 640px) {
  .inputGroup{
    flex-direction: column;
  }
  .inputGroup .inputContainer {
    margin-bottom: 25px;
  }
  .inputGroup .inputContainer:last-of-type {
    flex-direction: column;
    margin-bottom: 0px;
  }
}