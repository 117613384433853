
.submenuContainer {
  padding-top: 10px;
  width: 279px;
  position: absolute;
  top: 100%;
  left: -106px;
  z-index: 3;
}

.submenu {
  display: inline-block;
  position: relative;
  padding: 0;
  opacity: 1;
  border-radius: 0;
  border: 1px solid #eb7346;
  background-color: white;


}
.submenu::before, .submenu::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border:10px solid;
  border-color: transparent transparent white transparent;
  bottom: 100%;
  left: 50%;
  transform: translateX(-5px);
}
.submenu::before {
  border-width: 11px;
  border-color: transparent transparent #eb7346 transparent;
}

.tm {
  position: relative;
  font-size: 50%;
  line-height: 0;
  vertical-align: baseline;
  top: -.6em;
  left: -.3em;
}

.listItem {
  box-sizing: border-box;
  cursor: pointer;
  transition: background .3s;

  & a {
    display: inline-block;
    width: 100%;
    padding: 18px;
    font-size: 14px;
    text-decoration: none;
    color: #963223;
    font-weight: 600;
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid #eb7346;
  }

  &:hover {
    background: rgba(204, 204, 204, .2);
  }
}


