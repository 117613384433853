.pagePreview {
  height: 650px;
  padding:50px;
}
@media(max-width: 690px ) {
  .pagePreviewText {
    display: none;
  }
}
.pagePreviewText {
  margin-bottom: 40px;
}

.home{
  flex: 1 1 auto;
}

.buttons{
  display: flex;
  justify-content: center;
}

.button {
  display: flex;
  flex-flow: row wrap;
}

.button {
  margin-bottom: 30px;
}

.link{
  color: #eb7346;
  font-weight: 600;
}

.twoColumnBlocks{
  &:first-of-type{
    padding-right: 10px;
  }
  &:last-of-type{
    padding-left: 10px;
  }
}

.tm {
  position: relative;
  font-size: 50%;
  line-height: 0;
  vertical-align: baseline;
  top: -.6em;
  left: -.3em;
}

.partnersImagesTitle{
  text-align: center;
}

.partnersLinksWrapper{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}
.partnersLinksLink{
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 40px;
}

.decsriptionInnerWrap {
  flex: 1;
  margin: 90px 100px 75px 40px;
  max-width: 465px;
}
.decsriptionInnerWrap.reverse {
  margin-left: 100px;
  margin-right: 40px;
}

.center {
  text-align: center;
}

@media (max-width: 996px) {
  .decsriptionInnerWrap {
    margin-left: 40px;
    margin-right: 40px;
    max-width: none;
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .decsriptionInnerWrap.reverse {
    margin-left: 40px;
    margin-right: 40px;
  }
  .pagePreview {
    padding-top: 95px;
    padding-bottom: 95px;
  }

  .twoColumnBlocks{
    &:first-of-type{
      padding-right: 0;
    }
    &:last-of-type{
      padding-left: 0;
    }
  }

}
@media (min-width: 451px) and (max-width: 500px) {
  .pagePreview {
    height: 750px;
  }
}
@media (min-width: 360px) and (max-width: 450px) {
  .pagePreview {
    height: 850px;
  }
}
.customArrow{
  background: #eb7643 !important;
  z-index: 1;
  width: 25px !important;
  height: 25px !important;
  border-radius: 14px;
  padding-left: 3px !important;
  padding-top: 3px !important;
}

.slick-prev.customArrow  {
  left: 20px !important;
}
.customArrow .slick-prev:before {
  font-size: 40px;
  color: #EB7346;
  z-index: 1;
}
.customSclickPrev {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  width: 40px;
  height: 35px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  left: 20px;
  z-index: 1;

  &:before{
    content: '←';
    font-family: 'slick';
    font-size: 40px;
    line-height: 1;
    opacity: 1;
    color: #fff;
    -webkit-font-smoothing: antialiased;
  }

}

.customSclickNext {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  width: 40px;
  height: 35px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  right: 40px;
  z-index: 1;

  &:before{
    content: '→';
    font-family: 'slick';
    font-size: 40px;
    line-height: 1;
    opacity: 1;
    color: #fff;
    -webkit-font-smoothing: antialiased;
  }

}
.customDots {
  position: absolute;
  bottom: 40px;
  &:before {
    font-size: 20px !important;
    color: #fff !important;
  }
}
