.form {
  width: 100%;
  padding: 2rem 2.67rem 2rem;
  background: #fff;
}

.title {
  color: #EB7346;
  margin-bottom: 1.7rem!important;
}

.button.linkButton {
  color: #EB7346;
  text-decoration: underline;
}
.button.resendEmailButton {
  margin-bottom: 10px;
}
.error {
  color: red;
}
.message {
  color: green;
}
.hide {
  display: none;
}
.submit {
  width: 100%;
  min-width: auto;
  height: 45px;
  margin-top: .8rem;
  margin-bottom: 1rem;
  transition: background-color .1s;
}

.submitLabel {
  font-size: 20px!important;
}

.link {
  font-weight: 500;
}

.link:not(:last-of-type) {
  margin-bottom: 1rem;
}
