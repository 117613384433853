.container{
  flex: 1 1 auto;
}
.container .title {
  margin-bottom: 0.5rem;
}
.inputGroup {
  margin-bottom: 2rem;
}
.spinner {
  display: flex;
  justify-content: center;
}
.errorMessage {
  color: red;
}
.inputDoubleGroup {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > *:first-child {
    margin-right: 1rem;
  }

  > *:last-child {
    margin-left: 1rem;
  }
}

.inputContainer {
  flex: 1;
}

.toggleDescription {
  color: #EB7346;
  font-size: 20px;
  padding-bottom: 20px;
}

.toggleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.toggleText {
 padding-left: 5px;
}
.privacyText {
  font-size: 12px;
}
.singleLabel{
  display: block;
  font-size: 20px;
  margin:1.2rem 0 1.5rem 0;
  flex: 1;
}
.checkboxWrap{
  display: flex;
  flex-flow: row nowrap;
}
.checkBoxLabel{
  font-size: 16px !important;
}

.imageLoader {
  margin-top: 2rem;
}

.submitInfo {
  margin-top: 0.5rem;
}

.buttonsContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 4rem;
  margin-bottom: 2rem;
  .button{
    margin-bottom: 20px;
  }
}

.policyButton,
.termsOfUseButton {
  padding: 0;
  border: none;
  background: none;
  font-size: inherit;
  color: #0064b2;
  text-decoration: underline;
}

@media (max-width: 720px) {
  .inputDoubleGroup {
     .inputContainer {
      flex:1 1 100%;
      margin-left: 0;
      margin-right: 0;
       margin-bottom: 2rem;
       &:last-of-type{
           margin-bottom: 0;
       }
    }
  }
}