.burgerButton{
  position: relative;
  width: 24px;
  height: 24px;
  left: 0px;
  top: 0px;

  & .line {
    position: absolute;
    height: 2px;
    left: 0px;
    right: 0px;
    top: 0%;
    opacity: 1;
    background: black;

    &.first{
      top: 0%;
    }
    &.second{
      top: 40%;
    }
    &.third{
      top: 80%;
    }
  }
   & button {
     position: relative;
     left: 0px;
     top: 0px;
     width: 100%;
     height: 100%;
     margin: 0px;
     padding: 0px;
     border: none;
     font-size: 0px;
     background: transparent;
     cursor: pointer;
   }

}