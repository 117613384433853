.modalContent {
  padding: 10px 50px 50px 20px;

}
.modalContent p{
  font-size: 16px;

}
.buttonClose {
  border: none;
  background-color: white;
  font-weight: bold;
  padding: 0;
  align-self: center;
  text-align: end;
  width: 100%;
}

.buttonsBlock{
  button{
  margin-bottom: 20px;
    &:last-of-type{
      margin-bottom: 0;
    }
  }
}

.logInButton{
  display: inline-flex;
  justify-content: center;
  height: 40px;
  min-width: 167px;
  font-size: 14px;
  padding: 0 7px;
  margin-right: 30px;
  text-align: center;
  line-height: 1;
  transition: all 0.1s;
  background-color: #eb7346;
  border: 1px solid #eb7346;
  color: #fff;
}

.loginForm {
  max-width: 340px;
}
