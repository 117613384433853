.pagePreview {
  min-height: 370px;
  display: flex;
}

.contentWrap {
  display: flex;
  align-items: center;
}

.description {
  display: block;
  padding: 40px 0;
}
.description p{
  max-width: 500px;
}

.title {
  font-size: 40px;
  max-width: 540px;
  line-height: 1.4;
  margin-bottom: 20px;
}
@media(max-width: 690px ) {
  .title {
    max-height: 390px;
    max-width: 250px;
    overflow-y: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    background-color: rgba(0, 0, 0, 0.3);
  }
}
@media (min-width: 606px)and (max-width: 690px ) {
  .title {
    max-width: 450px;
  }
}
@media (min-width: 410px)and (max-width: 606px ) {
  .title {
    max-width: 350px;
  }
}
.text {
  color: white;
  padding-bottom: 0;
}

@media(max-width: 996px ){
  .pagePreview {
    min-height: 250px;
  }
}

