.textAreaBox {
  border: 2px solid lightgray;
  border-radius: 4px;
  flex: 1;
  font: inherit;
  padding: 0.5%;
}
.textAreaLabel {
  display: inline-block;
  font-size: 20px;
  margin-bottom: 0.8rem;
}
.textAreaWrap {
  position: relative;
  display: flex;
  flex-flow: column;
}
