.footer {
  display: flex;
  align-self: flex-end;
  width: 100%;
  min-height: 50px;
  background-color: #eb7346;
}

.button {
  border: none;
  background-color: #eb7346;
  color: white;
  font-weight: bold;
  padding: 0;
  align-self: center;
}
.footerLinkWrap a {
  display: inline-block;
  margin: 15px 20px 15px 0;
}
.footerLinks{
  color: #fff !important;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
}

// @media (max-width: 1000px) {
//   .footer {
//     display: none;
//   }
// }

@media (max-width: 430px) {
  .footerLinkWrap a{
    display: block;
    margin: 5px 0;
  }
  
}