.text {
  padding-bottom: 32px;
  text-align: left;
  font-family: 'Open Sans', 'OpenSans', OpenSans, opensans, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.6;
  color: #262d2d;
}

.text.emphasis {
  font-family: 'Quicksand', Quicksand, quicksand, Gotham, sans-serif;
  font-size: 36px;
  font-weight: 700;
  color: rgb(150, 50, 35);
  line-height: 1.4;
}

.text.alt {
  color: #ffffff;
}

.text.center {
  text-align: center;
}

.error, .success {
  display: inline-block;
  margin-bottom: 0;
  padding-bottom: 0;
}
.error {
  color: red;
}

.success {
  font-size: 25px;
  color: green;
}
