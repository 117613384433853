.sideBar {
  flex: 0 0 auto;
  box-shadow: 4px 0 3px grey;
  padding: 50px 30px 0 0;
}

@media (max-width: 1000px) {
  .sideBar{
    display: none;
  }
}