.markArea{
    overflow: hidden;
    max-width: 600px;
    margin: 20px 0;
    display: flex;
    user-select: none;
  
    &>div {
      width: 30px;
      height: 30px;
      border: 1px solid snow;
      border-radius: 50%;
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      flex-shrink: 0;
  
      &:hover {
        background-color: #0074a2;
      }
      
  
      &[data-active=true] {
        background: #0074a2;
      }
    }
}

@media (max-width: 768px) {
  .markArea>div {
    width: 25px;
    height: 25px;
    margin-right: 7px;
  }
} 