.ourStory {
  display: block;
  flex: 1 1 auto;
}

.decsriptionInnerWrap {
  margin: 32px 80px 60px 40px;
  max-width: 400px;
}

.centerChildren {
  display: flex;
  justify-content: center;
  align-items: center;
}

.triangle {
  display: inline-block;
  border: 26px solid transparent;
  border-left-color: white;
  transform: translate(36px, 0) scale(1.4);
  margin-left: 40px;
}
.partedWrap {
  margin-bottom: 20px;
}
.ourStoryColored {
  padding: 40px 0 10px 0  !important;
}
.partnerImagesBlock{
  margin: 0 0 60px 0;
}
.partnersImagesTitle{
  margin-bottom: 40px;
}
.partnersImages{
  width: 196px !important;
}
@media (max-width: 996px) {
  .triangle {
    margin-left: 0px;
  }
  .decsriptionInnerWrap {
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 32px;
    max-width: none;
  }
  .decsriptionInnerWrap.reverse {
    margin-left: 40px;
    margin-right: 40px;
  }
}

@media (max-width: 996px) {
  .decsriptionInnerWrap {
    margin-left: 0;
    margin-right: 0;
  }
}