.label {
  display: block;
  font-size: 20px;
  margin-bottom: 1.5rem;
}

.error {
  display: block;
  font-size: 20px;
  margin-bottom: 1.5rem;
  color: red;
}

.innerContainer {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.imageContainer {
  width: 153px;
  height: 153px;
  margin-right: 2.7rem;
  margin-bottom: 1rem;
  flex-shrink: 0;
}

.image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.detailsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 220px;
  margin-bottom: 1rem;
}

.description {
  font-size: 14px;
  padding-bottom: 1rem;
}

.button p {
  font-size: 20px!important;
}

.button {
  margin-bottom: 10px;
}

.input {
  display: none;
}
