.contentWrap {
  display: block;
  flex: 1;
  max-width: 1440px;
  padding: 0 40px;
  margin: 0 auto;
}

@media (max-width: 640px){
  .contentWrap {
    padding: 0 20px;
  }
}
