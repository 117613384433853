.app {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
}

.pageWrap{
  height: 100%;
  display: flex;
  flex-direction: column;
}