.loginAndUserProfileBar{
  position: relative;
  height: 100%;
  display: flex;
}

.bellContainer {
  position: relative;
  display: flex;
  justify-content: center;
}
.bellIconButtonStyle {
  border: none;
  background: none;

}
.userProfileBar {
  display: flex;
  flex-direction: row;
}
.loggedBlock {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-family: 'Quicksand', Quicksand, quicksand, Gotham, sans-serif;
  cursor: default;
  font-weight: 700;
}
.loggedBlock {
  text-decoration: none;
  cursor: pointer;
}

.imageBlock {
  display: flex;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  overflow: hidden;
}

.textBlock {
  padding: 0 0 0 10px;
}

.image {
  width: 100%;
  object-fit: cover;
}

.welcomeText {
  color: white;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}
