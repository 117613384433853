.label {
  display: inline-block;
  font-size: 20px;
  margin-bottom: .8rem;
}

.tips {
  margin-bottom: 15px;
  font-size: 12px;
}

.container {
  position: relative;
}

.checkboxContainer {
  margin-bottom: 1rem;
}

.errorMessage {
  top: 101%
}
