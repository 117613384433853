
.ourWork {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;

}

.platformerBlock{
  flex-grow: 1;
}

.list {
  list-style-type: none;
  margin-left: 1em;
  & li::before{
    content: "\2022";
    color: rgb(38, 45, 45);;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    font-size: 1em;
    margin-left: -1em;
  }
}

.choosePlatformBlock {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding-bottom: 40px;

  & .platformVariantTileWrapper{
    flex-grow: 1;
    display: flex;
    justify-content: center;
  }
  & .platformVariantTile {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    max-width: 536px;
    padding:0 40px;
    cursor: pointer;
    background-color: initial;
    border:none;
    opacity: 0.8;
    transition: all 0.2s;
    &:hover,&.notSelected:hover{
      opacity: 0.8;
    }
    &.selected{
      opacity: 1;
    }
    &.notSelected{
      opacity: 0.5;
    }
    & .imageWrapper{
      min-height: 260px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      padding:10px 10px 40px 10px;
      & img {
        width:100%
      }
    }
    & .descriptionWrapper{
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
    }
    & .title {
      line-height: 1;
      margin-bottom: 10px;
      font-size: 28px;
    }
  }
}

.chosenPlatformBlock{
  display: flex;
  flex-flow: row nowrap;
  &.chemoSafeBlock{
    flex-direction: row-reverse;
  }
  & .presentationWrapper,& .descriptionWrapper{
    width:50%
  }
  & .presentationWrapper {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    & .promo,& .promoLink, & .smallText {
      margin-left: 20px;
    }
    & .promoLink {
         font-family: 'Quicksand', Quicksand, quicksand, Gotham, sans-serif;
         font-weight: 600;
         color: #eb7346;
         font-size: 35.7px;
         line-height: 1.5;
         letter-spacing: 1px;
         max-width: 559px;
         text-decoration: underline;
         margin-bottom: 20px;
       }
    & .promo {
      font-weight: 600;
      color: #eb7346;
      font-size: 35.7px;
      line-height: 1.4;
      align-self: flex-end;
      letter-spacing: 1px;
      max-width: 559px;
      margin-bottom: 20px;
    }
    & .imageBlockLeft {
      position: relative;
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
      align-items: flex-start;
      & .deviceImageLeft {
        position: absolute;
        right: 20px;
        height: 91%;
        content: url(/images/tools/chemosafe_xxxlarge.png);
      }
    }
    & .imageBlockRight{
      position: relative;
      display: flex;
      flex-grow: 1;
      justify-content: flex-start;
      align-items: flex-start;
      overflow: hidden;
      & .deviceImageRight {
        height: 91%;
        content: url(/images/tools/guidlines_navigator_large.png);
        position: absolute;
        left: 10px;
      }
    }
  }

  & .descriptionWrapper{
      display: flex;
      justify-content: center;
    }

  & .descriptionInnerWrapper{
      flex-grow: 1;
      padding:0 30px;
      max-width: 536px;
    }

    & .title{
      margin-bottom: 20px;
      font-size: 38px;
    }
    & .smallText{
      padding-bottom: 0px;
      text-align: left;
      font-size: 20px;
      font-weight: bold;
      line-height: 1.6;
      font-family: 'Quicksand', Quicksand, quicksand, Gotham, sans-serif;
      max-width: 514px;
      color: #262d2d;
    }
}

.storeLinksBlock {
  margin-top: 80px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  & .storeImage{
    width:100%;
  }
  & .storeLink {
    display: inline-block;
    max-width: 200px;
    cursor: pointer;
  }
  & .storeLink:first-of-type{
    margin-bottom: 10px;
  }
}

.separator{
  align-self: stretch;
  justify-self: stretch;
  margin: 0px 0 30px;
  width: 2px;
  height: 240px;
  background-color: #eb7346;
}

.promoLink {
  font-family: 'Quicksand', Quicksand, quicksand, Gotham, sans-serif;
  font-weight: 600;
  color: #eb7346;
  font-size: 35.7px;
  line-height: 1.5;
  letter-spacing: 1px;
  max-width: 559px;
  text-decoration: underline;
  margin-bottom: 20px;
  background: none;
  padding: 0;
  text-align: start;
  border: none;
}
.promoLink:hover{
  text-decoration: none;
}

.wantToLearnMore {
  & .wantLearnMoreDescriptiveWrapper{
    background-color: #1B1B1B;
    padding: 60px 40px;
  }

  & .wantToLearnMoreBlockInnerWrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    max-width: 1195px;
    margin: 0 auto;
  }

  & .wantToLearnMoreText{
    color: white;
    max-width:350px;
    font-size: 36px;
  }

  & .buttonsBlock {
    padding-left: 44px;
    padding-right: 44px;
    display: flex;
    flex-flow: row nowrap;
    & .learnMoreButton{
      margin-top: 20px;
      margin-bottom: 20px;
    }
    & .learnMoreButton:last-of-type{
      margin-right: 0;
    }
  }
}

@media (max-width: 996px) {
  .choosePlatformBlock {
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
  }

  .platformVariantTile {
    padding: 0;

    & .imageWrapper{
      padding:10px 10px 20px 10px;
      min-height: initial;
    }
  }

  .platformVariantTile:first-of-type {
    margin-bottom:20px
  }

  .platformVariantTile:last-of-type {
    margin-top:20px
  }

  .separator{
    margin: 0 30px;
    width: auto;
    height: 2px;
  }
}

@media (max-width: 640px) {
  .wantToLearnMore {
    & .wantToLearnMoreBlockInnerWrapper {
      flex-flow: column nowrap;
    }
    & .buttonsBlock {
      flex-flow: column nowrap;
      & .learnMoreButton{
        margin-right: 0;
      }
    }
    & .wantToLearnMoreText{
      text-align: center;
    }
  }
  .chosenPlatformBlock{
    &.chemoSafeBlock, &.navigationBlock{
      flex-direction: column;
    }

    & .presentationWrapper, & .descriptionWrapper{
      width:auto
    }
    & .presentationWrapper{
      & .promo{
        text-align: center;
      }
    }
  }

  .storeLinksBlock{
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

