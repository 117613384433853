.container {
  display: flex;
  flex: 1 0 33%;
  flex-direction: column;
  align-items: center;
}

.imageContainer {
  display: flex;
  margin-bottom: 2rem;
  height: 204px;
}

.image {
  margin: auto;
  width:100%;
}

.description {
  text-align: center;
  font-weight: 600;
  color: #262d2d;
}

@media (max-width: 700px) {
  .container {
    flex: 1 0 50%;
  }
}
@media (max-width: 480px) {
  .container {
    flex: 1 0 100%;
  }
}