.memberCard {
  display: flex;
  flex-flow: column nowrap;
}

a.link {
  text-decoration: none;
}

.imgBlock {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-bottom: 15px;
  width: 152px;
  height: 152px;
  border-radius: 50%;
  overflow: hidden;
  background-image: url("/images/avatars/not_found.png");
}

.imgBlock img {
  width:100%;
}

.imgBlock .notFound {
  position: absolute;
}
.imgBlock .img {
  width:100%;
  height:100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.title {
  margin-bottom: 10px;
  color: #eb7346;
  font-family: 'Open Sans', 'OpenSans', OpenSans, opensans, sans-serif;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
}

.position,
.place,
.country {
  padding: 5px;
  margin-left: 20px;
  font-family: 'Open Sans', 'OpenSans', OpenSans, opensans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  color: #262d2d;
}
