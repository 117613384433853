.marker {
  cursor: pointer;
  font-size: 8px;
  font-weight: 300;
  line-height: 11px;
  text-align: center;
}

.text {
  fill: #ffffff;
  font-weight: 700;
}
