h1.title {
  font-size: 36px;
}
h2.title {
  font-size: 36px;
  margin-bottom: 60px;
}
h3.title {
  font-size: 36px;
  letter-spacing: 1px;
  margin-bottom: 37px;
}
h4.title {
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 0px;
  line-height: 1.6;
  margin-bottom: 30px;
}
h5.title {
  font-size: 72px;
  font-weight: 700;
}

.title {
  font-family: 'Quicksand', Quicksand, quicksand, Gotham, sans-serif;
  font-weight: 700;
  letter-spacing: 1.5px;
  color: #963223;
}
.title.alt {
  color: white;
}

.title.subtitle {
  color: #262d2d;
  font-weight: 600;
}

.title.secondary {
  color: #EB7346;
}
