.container{
}
.container .title {
  display: inline-block;
  margin-bottom: 0.5rem;
  flex-shrink: 2;
  padding-right: 20px;
}
.inputGroup {
  margin-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;
}
.profileInfoForm{
  margin: 20px 0 0 0 ;
}
.inputDoubleGroup {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > *:first-child {
    margin-right: 1rem;
  }

  > *:last-child {
    margin-left: 1rem;
  }
}
.emailLabel{
  display: block;
  font-size: 20px;
  margin-bottom: 1rem;
}
.email{
  font-size: 20px;
  color: #eb7346;
}
.inputContainer {
  flex: 1;
  margin-right: 20px;
}
.singleInputContainer{
  flex: 0.48 1;
}
.privacyText {
  font-size: 12px;
}

.submitInfo {
  margin-top: 0.5rem;
}

.buttonsContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 4rem;
  margin-bottom: 2rem;
}
.profileInfoFormButtons p{
  font-size: 20px !important;
}
.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 640px) {
  .inputGroup{
    flex-direction: column;
  }
  .inputGroup .inputContainer {
    margin-bottom: 25px;
  }
  .inputGroup .inputContainer:last-of-type {
    flex-direction: column;
    margin-bottom: 0px;
  }
}
