.bm-burger-button {
  display: none;
  position: absolute;
}
.bm-menu-wrap {
    position: fixed;
    height: 100%;
}

.bm-menu {
    background: #262D2D;
    padding: 0;
    font-size: 1.15em;
    box-shadow: inset 0 0 10px #000;
}
.bm-item-list{
    display: flex;
    flex-flow: column nowrap;
}
.bm-item{
    flex-grow: 1;
    display: flex!important;
    flex-flow: column nowrap;
}

