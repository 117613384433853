.chemoSafe{
  display: block;
  flex: 1 1 auto;

  &VideoContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 1440px;
    padding: 0 40px;
    margin: 40px auto 0;

    &>iframe {
      object-fit: cover;
      width: 80%;
      max-width: 100%;
      height: 550px;
    }

    &>video {
      object-fit: cover;
      width: 80%;
    }
  }
}
.readBlockImg{
  max-width: 120%;
}
.downloadBlockImgLeft{
  max-width: 140%;
  width: 796px;

  margin:30px 0 0 -300px;
}
.storeImg{
  max-width: 100%;
  display: block;
  width: 228px;
  margin: 10px 0;
}
.comingSoon{
  font-style: italic;
  color: #828282;
}

.hashedLink{
  font-size: 20px;
}

@media (max-width: 996px){
  .downloadBlockImgLeft{
    margin: 0 0 0 -100px;
  }
  .readBlockImg{
    max-width: 100%;
  }
}
@media (max-width: 780px) and (min-width: 480px) {
  .downloadBlockImgLeft{
    width: 600px;
  }
}

@media (max-width: 830px) and (min-width: 600px) and (orientation: portrait) {
  .chemoSafeVideoContainer {
    &>iframe {
      height: 330px;
    }
  }
}

@media (max-width: 768px) and (min-width: 600px) and (orientation: portrait) {
  .chemoSafeVideoContainer {
    &>iframe {
      height: 330px;
    }
  }
}
/* Target iPads in landscape orientation */
@media (max-width: 1024px) and (min-width: 769px) and (orientation: landscape) {
  .chemoSafeVideoContainer {
    &>iframe {
      height: 330px;
    }
  }
}

@media (max-width: 640px){
  .chemoSafeVideoContainer {
    padding: 0 20px;

    &>h4 {
      font-size: 18px;
    }
    &>iframe {
      width: 100%;
      height:200px
    }
    &>video {
      width: 100%;
    }
  }
}