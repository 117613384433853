.title {
  margin-bottom: 30px;
  color: #eb7346;
}
.content{
  padding: 100px 0;
}

.formWrapper {
  display: block;
  max-width: 500px;
  margin: 0 auto;
  box-shadow: 0px 2px 5px 0px rgba(143, 143, 143, 0.33);
}

