.inputs {
  max-width: 500px;
  margin-bottom: 20px;
}

.input {
  margin-bottom: 20px;
}
.input:last-of-type {
  margin-bottom: 0px;
}

.text {
  font-size: 20px;
  padding-bottom: 20px;
}

.coutionText {
  font-size: 12px;
}

.button {
  display: flex;
}

.buttonBlock {
  display: flex;
  max-width: 500px;
}
.error {
  color: red;
  font-size: 14px;

}
.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirmUserlogin {
  color:#eb7346
}

@media (max-width: 640px) {
  .buttonBlock {
    flex-direction:row;
    flex-wrap: wrap;
    & * {
      margin-bottom:10px
    }
    & *:last-child{
      margin-bottom: 0;
    }
  }
}

