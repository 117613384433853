.rightColumn{
  display: block;
  width: 275px;
  margin: 15px 0;
}
.rightColumn:first-child{
  padding-top: 110px;
}
.rightColumn h1{
  font-size: 140px;
  color: #A03C6E;
  margin: 0 0 40px 0;
}
.rightColumn p{
  font-size: 20px;
  color: #963223;
  font-weight: bold;
}

@media (max-width: 400px){
  .rightColumn h1{
    font-size: 100px;
  }
  .rightColumn{
    display: block;
    width: 225px;
    margin: 15px 0;
  }
}