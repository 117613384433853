.wrapper{
  max-width: 475px;
  padding: 15px 10px;
  border: 10px solid #ebe9e9;
  border-radius: 5px; 
  background-color: #1d3549;
  color: white;
  font-family: 'Open Sans', 'OpenSans', OpenSans, opensans, sans-serif;

  position: fixed;
  bottom: 70px;
  right: 20px;
  z-index: 200;
}

.titleBlock{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.icon{
  font-size: 35px;
  margin-right: 10px;
  cursor: pointer;
}

.title{
  margin-bottom: 10px;
  font-size: 22px;
}

.text{
  margin-bottom: 7px; 
  font-size: 15px;
}

.like{
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

.textareaTextLength{
  margin: 0px 10px 15px;
  color: grey;
  font-size: 12px;
  font-family: system-ui;
}

textarea{
  max-width: 100%;
  max-height: 150px;
  padding: 10px;
  border: 3px solid #ffffff;
  border-radius: 5px;
  background-color: #1d3549;
  outline: none;
  color: #ffffff;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  &:focus{
    border: 3px solid #3999c9;
  }
}

.buttonBlock{
  display: flex;
  align-items: center;
  justify-content: center;
}

.submitButton{
  width: 100px;
  height: 40px;
  border: 2px solid #0074a2;
  border-radius: 5px; 
  background-color: #0074a2;
  color: white;
  cursor: pointer;
  &:hover{
    color: #ccc;
  }
  &:active{
    border: 3px solid #3999c9;
  }

}

@media (max-width: 768px) {
  .wrapper {
    right: 0;
    width: 100%;
  }
} 