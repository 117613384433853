.painManagement {
  display: block;
  flex: 1 1 auto;
}
.painFooter {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-size: cover;
  min-height: 1283px;
  padding: 30px 10% 0 0;
  box-shadow: inset -400px 0px 100px 0px #d8d8d8d4;
}
.link{
  color: #eb7346;
}