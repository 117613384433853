.colored {
  position: relative;
  padding: 50px 0;
  background-color: black;
  background-size: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.content {
  z-index: 5;
  position: relative;
}

.colored .title {
  text-align: center;
  margin-bottom: 50px;
}

.colored p,
h1,
h2,
h3,
h4,
h5 {
  color: white;
}

.colored .text.emphasis {
  font-family: 'Quicksand', Quicksand, quicksand, Gotham, sans-serif;
  font-size: 24px;
  font-weight: 700;
}

@media (max-width: 996px) {
  .colored .title {
    margin-bottom: 40px;
  }
}
